<template>
  <!-- Dates -->

  <div class="grid grid-cols-2 gap-5 mb-8 font-paragraph-500">
    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-4 gap-2">
        <h1 class="headingOne text-base self-center font-normal col-span-2">
          Select Date Range
        </h1>
        <button
          class="secondaryBtn text-xs focus:bg-[#035795] focus:border-[#035795] active:bg-[#035795]"
          @click="getCurrentMonth()"
        >
          This Month
        </button>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="getCurrentYear()"
        >
          This Year
        </button>
      </div>
    </div>

    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-3 gap-2">
        <base-date-time-picker
          v-model="fromDate"
          label="From"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <base-date-time-picker
          v-model="toDate"
          label="To"
          :minDate="fromDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="customizeDateRange()"
        >
        Customize Date Range
        </button>
      </div>
    </div>
  </div>

  <!-- Tabs -->
  <div class="grid grid-cols-4 gap-2 mb-4">
    <button class="btnTab" @click="getAllergy(); determineCurrentTab('Allergy');">
      Allergy
      Data
    </button>
    <button class="btnTab" @click="getMedicalEvent(); determineCurrentTab('MedicalEvent')">
      Medical Event
      Data
    </button>
    <button class="btnTab" @click="getSurgeryEvent(); determineCurrentTab('SurgeryEvent')">
      Surgery Event
      Data
    </button>
    <button class="btnTab" @click="getFamilyEvent(); determineCurrentTab('FamilyEvent')">
      Family Medical Event
      Data
    </button>
    <button class="btnTab" @click="getExerciseEvent(); determineCurrentTab('ExerciseEvent')">
      Exercise Event
      Data
    </button>
    <button class="btnTab" @click="getMedication(); determineCurrentTab('Medication')">
      Medication
      Data
    </button>
    <button class="btnTab" @click="getGeneralEvent(); determineCurrentTab('GenrealEvent')">
      General Event
      Data
    </button>
  </div>

  <!-- Chart -->
  <div class="relative bg-gray-300 rounded-lg rounded-t-none p-10 mb-5">
    <p class="headingOne font-bold text-lg self-center" v-if="allergyTab == true">
      Allergy
      Data
    </p>
    <p class="headingOne font-bold text-lg self-center" v-if="medicalEventTab == true">
      Medical Event
      Data
    </p>
    <p class="headingOne font-bold text-lg self-center" v-if="surgeryEventTab == true">
      Surgery Event
      Data
    </p>
    <p class="headingOne font-bold text-lg self-center" v-if="familyEventTab == true">
      Family Medical Event
      Data
    </p>
    <p class="headingOne font-bold text-lg self-center" v-if="exerciseEventTab == true">
      Exercise Event
      Data
    </p>
    <p class="headingOne font-bold text-lg self-center" v-if="medicationTab == true">
      Medication
      Data
    </p>
    <p class="headingOne font-bold text-lg self-center" v-if="genrealEventTab == true">
      General Event
      Data
    </p>
    <EasyDataTable
      :headers="headers"
      :items="items"
      :theme-color="themeColor"
      :loading="retrievingData"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, nextTick, defineEmits, defineProps } from "vue";
  import base from "../../services/api";
  import { formatISO } from "date-fns";

  import BaseDateTimePicker from "../../../src/assets/Form/BaseDateTimePicker.vue";

  const emit = defineEmits(["retrieveTabId"]);
  const emitDataID = async (id) => {
    emit("retrieveTabId", id);
  };

  const props = defineProps({
    securityCode: Number
  });

  const themeColor = "#0b7db6";
  const retrievingData = ref(false);

  const fromDate = ref("");
  const toDate = ref("");

  const dataList = ref([]);
  const headers = ref([]);
  const items = ref([]);

  const allergyTab = ref(true);
  const medicalEventTab = ref(false);
  const surgeryEventTab = ref(false);
  const familyEventTab = ref(false);
  const exerciseEventTab = ref(false);
  const medicationTab = ref(false);
  const genrealEventTab = ref(false);

  const getCurrentDate = async () => {
    const currentDate = new Date();
    toDate.value = formatISO(currentDate);
  }

  const getCurrentYear = async () => {
    const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
    fromDate.value = formatISO(firstDayYear);
    toDate.value = formatISO(new Date);
    refreshTabs();
  };

  const customizeDateRange = async () => {
      refreshTabs();
  };

  const getCurrentMonth = async () => {
    const firstDayMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      fromDate.value = formatISO(firstDayMonth);
      toDate.value = formatISO(new Date);
      refreshTabs();
  };

  const determineCurrentTab = async (tabType) => {

  /*
    This function is reponsible ensuring that the user does not get taken to a tab they do not wish to view
    when adding data to customizing dates
  */
    if(tabType == "Allergy"){
      allergyTab.value = true;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "MedicalEvent"){
      allergyTab.value = false;
      medicalEventTab.value = true;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "SurgeryEvent"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = true;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "FamilyEvent"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = true;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "ExerciseEvent"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = true;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "Medication"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = true;
      genrealEventTab.value = false;
    }
    if(tabType == "GenrealEvent"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = true;
    }
  }

  const refreshTabs = async () => {

    /*
      This function is reponsible for refreshing graphs when a user adds data or selects date ranges
      This is to ensure that data can instantly retrieved without the user needing to refresh the page everytime
    */

    if(allergyTab.value == true){
      getAllergy();
    }
    if(medicalEventTab.value == true){
      getMedicalEvent();
    }
    if(surgeryEventTab.value == true){
      getSurgeryEvent();
    }
    if(familyEventTab.value == true){
      getFamilyEvent();
    }
    if(exerciseEventTab.value == true){
      getExerciseEvent();
    }
    if(medicationTab.value == true){
      getMedication();
    }
    if(genrealEventTab.value == true){
      getGeneralEvent();
    }
  };

  const getAllergy = async () => {
    emitDataID('54');
    headers.value = [];
    items.value = [];
    retrievingData.value = true;

    base
      .get(`/v2/profiles/allergy/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.data;
            if(headers.value.length === 0){
              headers.value.push(
                {text: "Date", value: "date", sortable: true},
                {text: "Description", value: "description"}
              );
            }

            dataList.value.forEach((d) => {
              if(items.value.length < dataList.value.length){
                if(d.date >= fromDate.value.substring(0,10) && d.date <= toDate.value.substring(0,10) ){
                  items.value.push(d);
                }
              }
            });
            retrievingData.value = false;
          }
        })
        .catch(() => {
          console.error("getAllergy Error");
        });
  };

  const getMedicalEvent = async () => {
    emitDataID('55');
    headers.value = [];
    items.value = [];
    retrievingData.value = true;

    base
      .get(`/v2/profiles/medical-event/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          if(headers.value.length === 0){
              headers.value.push(
                {text: "Date", value: "date", sortable: true},
                {text: "Description", value: "description"},
                {text: "Comment", value: "comment"},
                {text: "Current Treatment", value: "current_treatment"},
                {text: "Complications", value: "complications"},
                {text: "Date of Treatment", value: "date_of_treatment"},
                {text: "Resolved", value: "resolved"},
              );
          }

          dataList.value.forEach((d) => {
            if(items.value.length < dataList.value.length){
              if(d.date >= fromDate.value.substring(0,10) && d.date <= toDate.value.substring(0,10) ){
                items.value.push(d);
              }
            }
          });
          retrievingData.value = false;
      }
    })
    .catch(() => {
      console.error("getMedicalEvent Error");
    });
  };

  const getSurgeryEvent = async () => {
    emitDataID('56');
    headers.value = [];
    items.value = [];
    retrievingData.value = true;

    base
      .get(`/v2/profiles/surgery-event/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          if(headers.value.length === 0){
              headers.value.push(
                {text: "Date", value: "date", sortable: true},
                {text: "Description", value: "description"},
                {text: "Comment", value: "comment"},
                {text: "Current Treatment", value: "current_treatment"},
                {text: "Complications", value: "complications"},
                {text: "Date of Treatment", value: "date_of_treatment"},
                {text: "Resolved", value: "resolved"},
              );
          }

          dataList.value.forEach((d) => {
            if(items.value.length < dataList.value.length){
              if(d.date >= fromDate.value.substring(0,10) && d.date <= toDate.value.substring(0,10) ){
                items.value.push(d);
              }
            }
          });
          retrievingData.value = false;
        }
      })
      .catch(() => {
        console.error("getSurgeryEvent Error");
      });
  };

  const getFamilyEvent = async () => {
    emitDataID('57');
    headers.value = [];
    items.value = [];
    retrievingData.value = true;

    base
      .get(`/v2/profiles/family-event/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          if(headers.value.length === 0){
              headers.value.push(
                {text: "Date", value: "date", sortable: true},
                {text: "Description", value: "description"},
                {text: "Comment", value: "comment"},
                {text: "Current Treatment", value: "current_treatment"},
                {text: "Complications", value: "complications"},
                {text: "Date of Treatment", value: "date_of_treatment"},
                {text: "Resolved", value: "resolved"},
              );
          }

          dataList.value.forEach((d) => {
            if(items.value.length < dataList.value.length){
              if(d.date >= fromDate.value.substring(0,10) && d.date <= toDate.value.substring(0,10) ){
                items.value.push(d);
              }
            }
          });
          retrievingData.value = false;
        }
      })
      .catch(() => {
        console.error("getFamilyEvent Error");
      });
  };

  const getExerciseEvent = async () => {
    emitDataID('58');
    headers.value = [];
    items.value = [];
    retrievingData.value = true;

    base
      .get(`/v2/profiles/exercise-event/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          if(headers.value.length === 0){
            headers.value.push(
              {text: "Date", value: "date", sortable: true},
              {text: "Description", value: "description"},
              {text: "Date of Last Episode", value: "date_of_last_episode"},
              {text: "No of Concussions", value: "no_of_concussions"},
            );
          }

          dataList.value.forEach((d) => {
            if(items.value.length < dataList.value.length){
              if(d.date >= fromDate.value.substring(0,10) && d.date <= toDate.value.substring(0,10) ){
                items.value.push(d);
              }
            }
          });
          retrievingData.value = false;
        }
      })
      .catch(() => {
        console.error("getExerciseEvent Error");
      });
  };

  const getMedication = async () => {
    emitDataID('59');
    headers.value = [];
    items.value = [];
    retrievingData.value = true;

    base
      .get(`/v2/profiles/medication/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          if(headers.value.length === 0){
            headers.value.push(
              {text: "Date", value: "date", sortable: true},
              {text: "Description", value: "description"},
              {text: "Dose", value: "dose"},
            );
          }

          dataList.value.forEach((d) => {
            if(items.value.length < dataList.value.length){
              if(d.date >= fromDate.value.substring(0,10) && d.date <= toDate.value.substring(0,10) ){
                items.value.push(d);
              }
            }
          });
          retrievingData.value = false;
        }
      })
      .catch(() => {
        console.error("getMedication Error");
      });
  };

  const getGeneralEvent = async () => {
    emitDataID('60');
    headers.value = [];
    items.value = [];
    retrievingData.value = true;

    base
      .get(`/v2/profiles/general-event/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          if(headers.value.length === 0){
            headers.value.push(
              {text: "Date", value: "date", sortable: true},
              {text: "Description", value: "description"},
            );
          }

          dataList.value.forEach((d) => {
            if(items.value.length < dataList.value.length){
              if(d.date >= fromDate.value.substring(0,10) && d.date <= toDate.value.substring(0,10) ){
                items.value.push(d);
              }
            }
          });
          retrievingData.value = false;
        }
      })
      .catch(() => {
        console.error("getGeneralEvent Error");
      });
  };

  onMounted(() => {
    nextTick(function () {
      getCurrentDate();
      getCurrentYear();
    });
  });

</script>

