<template>
  <NavComponent></NavComponent>
  <HeaderShape></HeaderShape>

  <section class="sectionView -mt-5 text-paragraph-500">
    <div class="container mx-auto px-5">
      <!-- HEADER -->
      <div class="headerDiv">
        <base-card class="col-span-4 bg-white grid grid-cols-6">
          <img
            class="h-28 w-28 self-center"
            src="https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/research.png"
          />
          <div class="col-span-5 justify-center self-center">
            <p class="headingOne font-bold text-paragraph-500">Pain Profile</p>
            <p
              class="mt-3 text-sm"
            >View and track your pain level before, during and after exercise.</p>
          </div>
        </base-card>
        <base-card class="col-span-2 mx-auto grid grid-cols-2 content-center">
          <p class="headingOne text-lg font-normal self-center">
            Add
            <span class="font-bold">Pain</span> Data
          </p>

          <div>
            <button
              @click="openModal()"
              class="flex primaryBtn h-12 w-12 float-right mx-auto rounded-full self-center"
            >
              <span class="text-4xl self-center">+</span>
            </button>
          </div>
        </base-card>
      </div>

      <!-- CHARTS -->
      <PainCharts :key="painChartKey"></PainCharts>
    </div>
  </section>

  <!-- MODAL -->
  <AddModal v-if="modalOpen">
    <template v-slot:header>Pain Score Profile</template>
    <template v-slot:content>
    <div class="grid grid-cols-3 mt-5 gap-5">
      <div>
        <base-select
          v-model="selectedOption"
          refKey="id"
          label="Select Data Type"
          :items="myOptions"
          item-text="name"
          item-value="id"
          :excludeOption="false"
        ></base-select>
      </div>
      <div class="col-span-2">
        <div v-if="myForm">
          <div class="modalForm">
            <h2 class="headingTwo mb-5"><strong>Enter Data Values</strong></h2>
            <base-date-time-picker
              v-model="selectedDate"
              label="Pick a Date"
              :maxDate="new Date()"
              required
            ></base-date-time-picker>
            <template v-for="(fi, j) in myForm" :key="j">
              <div
                  v-if="fi.value_type == 'integer' && fi.unit != ''"
                  class="form-control w-full"
                >
                  <base-input
                    v-model="submitForm[fi.name]"
                    refKey="fi.name"
                    :label="fi.ui_name"
                    :additionalLabel="true"
                    :additionalLabelValue="fi.unit"
                    required
                    type="number"
                  ></base-input>
                </div>
                <div
                  v-else-if="fi.value_type == 'integer' && fi.unit == ''"
                  class="form-control w-full"
                >
                  <base-input
                    v-model="submitForm[fi.name]"
                    refKey="fi.name"
                    :label="fi.ui_name"
                    :additionalLabel="false"
                    required
                    type="number"
                  ></base-input>
                </div>
            </template>
            <div class="flex justify-between">
              <div></div>
              <button class="primaryBtn w-40 mt-5" @click="addData()">Add Data</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </template>
    <template v-slot:footer>
      <button class="secondaryBtn w-40" @click="closeModal()">Close</button>
    </template>
    <template v-slot:background>
      <div v-if="modalOpen" class="opacity-75 fixed inset-0 z-40 bg-black"></div>
    </template>
  </AddModal>
</template>

<script setup>
  import base from "../services/api";
  import { useStore } from "vuex";
  import { computed, ref, watch } from "vue"
  import { map, find } from "lodash";
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import PainCharts from "../components/charts/PainCharts.vue";
  import AddModal from "../assets/AddModal.vue";
  import BaseCard from "../assets/BaseCard.vue";
  import HeaderShape from "../assets/HeaderShape.vue";
  import NavComponent from "../components/NavComponent.vue";

  import BaseInput from "../../src/assets/Form/BaseInput.vue";
  import BaseDateTimePicker from "../../src/assets/Form/BaseDateTimePicker.vue";
  import BaseSelect from "@/assets/Form/BaseSelect.vue";

  const selectedDate = ref();
  const date = ref();

  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);

  const myProfile = computed(() => store.getters['painProfile']);
  const myOptions = computed(() => map(myProfile.value?.dataset_set, ({ name, id }) => ({ name, id }))) ?? [];
  const selectedOption = ref(null);

  const submitForm = ref({});

  const myForm = computed(() => find(myProfile.value?.dataset_set, { id: selectedOption.value * 1 })?.structure?.elements);

  // modal
  const modalOpen = ref(false);

  function openModal() {
    modalOpen.value = true;
  }

  function closeModal() {
    modalOpen.value = false;
    selectedDate.value = "";
  }

  const painChartKey = ref(0);
  // Refreshes the entirey of all tabs for the charts componenet
  const forceRerender = () => {
    painChartKey.value += 1;
    selectedDate.value = "";
  };

  watch(selectedOption, () => {
    submitForm.value = {}
  });

  const parseDateFormat = async (dateString) => {
    date.value = new Date(dateString);
  };

  const addData = async () => {

    const postAPI = find(myProfile.value?.dataset_set, { id: selectedOption.value * 1 })?.post_api_name;
    const apiName = "/v2/research/";

    if(selectedDate.value === undefined || selectedDate.value == ''){
      toast('Please provide a date!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      parseDateFormat(selectedDate.value);
      base
        .post(
          `${apiName}${postAPI}/`,
          { ...submitForm.value, created_on: date.value, },
          {
            headers: {
              Authorization: `ASSIC ${user.value.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data?.status === "success") {
            toast('Pain Score Data Successfully Added!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            closeModal();
            forceRerender();
          } else {
            toast('Please ensure that all fields have been filled in!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          toast('Unable To Add Pain Score Data!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };
</script>
