<template>
  <NavComponent></NavComponent>
  <HeaderShape></HeaderShape>

  <ConfirmationModal v-if="noSecurePin === false">
    <template v-slot:header>No Security Pin</template>
    <template v-slot:content>
      You don't currently have a secure pin, please go to the profile section to generate one
    </template>
    <template v-slot:footer>
      <div class="flex justify-around">
        <router-link
          class="w-full"
          to="/profile"
        >
          <button class="primaryBtn w-40">Profile</button>
        </router-link>
      </div>
    </template>
    <template v-slot:background>
      <div
        v-if="access === false"
        class="opacity-75 fixed inset-0 z-40 bg-black"
      ></div>
    </template>
  </ConfirmationModal>

  <ConfirmationModal v-if="access === false">
    <template v-slot:header>Enter Security Pin</template>
    <template v-slot:content>
      <div class="form-control w-full mb-3">
        <base-input
          v-model="access_security_pin"
          refKey="access_security_pin"
          required
          noDetails
          label="Security Pin"
          :type="pinVisible ? 'text' : 'password'"
        >
          <template v-slot:append>
            <button
              class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
              @click.prevent="pinVisible = !pinVisible"
              type="button"
            >
              <EyeIcon v-if="pinVisible" class="w-6 h6" />
              <EyeSlashIcon v-else class="w-6 h6" />
            </button>
          </template>
        </base-input>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex justify-around">
        <button
          class="primaryBtn w-40"
          @click="
            accessData();
          "
        >
          Enter
        </button>
      </div>
    </template>
    <template v-slot:background>
      <div
        v-if="access === false"
        class="opacity-75 fixed inset-0 z-40 bg-black"
      ></div>
    </template>
  </ConfirmationModal>

  <section class="sectionView -mt-5 text-paragraph-500">
    <div class="container mx-auto px-5">
      <!-- HEADER -->
      <div class="headerDiv">
        <base-card class="col-span-4 bg-white grid grid-cols-6">
          <img
            class="h-28 w-28 self-center"
            src="https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/labtest.png"
          />
          <div class="col-span-5 justify-center self-center">
            <p class="headingOne font-bold text-paragraph-500">Laboratory Tests Profile</p>
            <p
              class="mt-3 text-sm"
            >Any investigation e.g. laboratory test can be entered and tracked.</p>
          </div>
        </base-card>
        <base-card class="col-span-2 mx-auto grid grid-cols-2 content-center">
          <p class="headingOne text-lg font-normal self-center">
            Add
            <span class="font-bold">Laboratory Tests</span> Data
          </p>

          <div>
            <button
              @click="openModal()"
              class="flex primaryBtn h-12 w-12 float-right mx-auto rounded-full self-center"
            >
              <span class="text-4xl self-center">+</span>
            </button>
          </div>
        </base-card>
      </div>

      <!-- CHARTS -->
      <div v-if="!loading">
        <LaboratoryCharts :key="labChartKey" :security-code="access_security_pin" @retrieve-tab-id="identifyTab"></LaboratoryCharts>
      </div>
    </div>
  </section>

  <!-- MODAL -->
  <AddModal v-if="modalOpen">
    <template v-slot:header>Laboratory Tests Data</template>
    <template v-slot:content>
      <div class="grid grid-cols-3 mt-5 gap-5">
        <div>
          <base-select
            v-model="selectedOption"
            refKey="id"
            label="Select Data Type"
            :items="myOptions"
            item-text="name"
            item-value="id"
            :excludeOption="true"
            :excludeOptionIdValue="62"
          ></base-select>
        </div>
        <div class="col-span-2">
          <div v-if="myForm">
            <div class="modalForm">
              <h2 class="headingTwo mb-5"><strong>Enter Data Values</strong></h2>
              <base-date-time-picker
                v-model="selectedDate"
                label="Pick a Date"
                :maxDate="new Date()"
                required
              ></base-date-time-picker>
              <template v-for="(fi, j) in myForm" :key="j">
                <div
                  v-if="fi.value_type == 'integer' && fi.unit != ''"
                  class="form-control w-full"
                >
                  <base-input
                    v-model="submitForm[fi.name]"
                    refKey="fi.name"
                    :label="fi.ui_name"
                    :additionalLabel="true"
                    :additionalLabelValue="fi.unit"
                    required
                    type="number"
                  ></base-input>
                </div>
                <div
                  v-else-if="fi.value_type == 'integer' && fi.unit == ''"
                  class="form-control w-full"
                >
                  <base-input
                    v-model="submitForm[fi.name]"
                    refKey="fi.name"
                    :label="fi.ui_name"
                    :additionalLabel="false"
                    required
                    type="number"
                  ></base-input>
                </div>
                <div v-else-if="fi.value_type == 'decimal' && fi.unit != ''" class="form-control w-full">
                  <base-input
                    v-model="submitForm[fi.name]"
                    refKey="fi.name"
                    :label="fi.ui_name"
                    :additionalLabel="true"
                    :additionalLabelValue="fi.unit"
                    required
                    type="number"
                    step=".01"
                  ></base-input>
                </div>
                <div v-else-if="fi.value_type == 'decimal' && fi.unit == ''" class="form-control w-full">
                  <base-input
                    v-model="submitForm[fi.name]"
                    refKey="fi.name"
                    :label="fi.ui_name"
                    :additionalLabel="false"
                    required
                    type="number"
                    step=".01"
                  ></base-input>
                </div>
              </template>
            <div>
              <base-input
                v-model="data_security_pin"
                refKey="data_security_pin"
                required
                noDetails
                label="Security Pin"
                :type="pinVisible ? 'text' : 'password'"
              >
                <template v-slot:append>
                  <button
                    class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
                    @click.prevent="pinVisible = !pinVisible"
                    type="button"
                  >
                    <EyeIcon v-if="pinVisible" class="w-6 h6" />
                    <EyeSlashIcon v-else class="w-6 h6" />
                  </button>
                </template>
              </base-input>
            </div>
            <div class="flex justify-between">
              <div></div>
              <button class="primaryBtn w-40 mt-5" @click="addData()">Add Data</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </template>
    <template v-slot:footer>
      <button class="secondaryBtn w-40" @click="closeModal">Close</button>
    </template>
    <template v-slot:background>
      <div v-if="modalOpen" class="opacity-75 fixed inset-0 z-40 bg-black"></div>
    </template>
  </AddModal>
</template>

<script setup>
  import LaboratoryCharts from "../components/charts/LaboratoryCharts.vue";
  import BaseCard from "../assets/BaseCard.vue";
  import HeaderShape from "../assets/HeaderShape.vue";
  import NavComponent from "../components/NavComponent.vue";
  import { ref, onMounted, computed, watch } from "vue";
  import { useStore } from "vuex";
  import AddModal from "../assets/AddModal.vue";
  import ConfirmationModal from "../assets/ConfirmationModal.vue";
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import base from "../services/api";
  import { map, find } from "lodash";
  import BaseInput from "../../src/assets/Form/BaseInput.vue";
  import BaseDateTimePicker from "../../src/assets/Form/BaseDateTimePicker.vue";
  import BaseSelect from "@/assets/Form/BaseSelect.vue";
  import { formatISO } from "date-fns";
  import { EyeSlashIcon, EyeIcon } from '@heroicons/vue/24/solid';

  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);
  const access = ref(true);
  const noSecurePin = ref(true);
  const loading = ref(true);
  const access_security_pin = ref(""); // Password for re-authentication
  const data_security_pin = ref("");
  const pinVisible = ref(false);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `ASSIC ${user.value.token}`,
  };

  const selectedDate = ref();
  const date = ref();
  const tab_id = ref();

  const verifySecurePin = async() => {
    base.post('/v2/user/verify-secure-pin/')
    .then((response) => {
      if (response.data.data == 'User has no secure pin') {
        access.value = true
        noSecurePin.value = false
      } else {
        access.value = false
      }
      loading.value = false
    })
  }

  const identifyTab = async (id) => {
    if(access_security_pin.value != ""){
      access.value = true;
    } else {
      access.value = false;
    }
    tab_id.value = id;
  };

  const myProfile = computed(() => store.getters['laboratoryProfile']);
  const myOptions = computed(() => map(myProfile.value?.dataset_set, ({ name, id }) => ({ name, id }))) ?? [];
  const selectedOption = ref(null);

  const submitForm = ref({});

  const myForm = computed(() => find(myProfile.value?.dataset_set, { id: selectedOption.value * 1 })?.structure?.elements)

  watch(selectedOption, () => {
    submitForm.value = {}
  });

  const parseDateFormat = async (dateString) => {
    date.value = new Date(dateString);
  };

  // modal
  const modalOpen = ref(false);

  function openModal() {
    modalOpen.value = true;
  }

  function closeModal() {
    modalOpen.value = false;
    selectedDate.value = "";
  }

  const labChartKey = ref(0);
  // Refreshes the entirey of all tabs for the charts componenet
  const forceRerender = () => {
    labChartKey.value += 1;
    selectedDate.value = "";
  }

  let startOfTheYear = new Date(new Date().getFullYear(), 0, 1);
  let currentDate = new Date();

  const accessData = async () => {
    base
      .post(
        `/v2/profiles/secure/get/${tab_id.value}/?secure_pin=${access_security_pin.value}`,
        {
          date_from: formatISO(startOfTheYear),
          date_to: formatISO(currentDate),
        }, { headers })
        .then((response) => {
        if (response.data?.status === "success") {
          toast('Laboratory Data Successfully Retrieved!', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
          forceRerender();
          access.value = true;
        } else if(response.data?.message === "No data available"){
            toast('No data availible!', {
                type: 'warning',
                position: toast.POSITION.BOTTOM_RIGHT,
            });

            access.value = true;
        } else {
          toast(response.data?.message, {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((e) => {
        if(e.response.statusText == "Forbidden"){
          toast('Security Pin Denied!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast('Something went wrong!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        data_security_pin.value = "";
      });
  }

  const addData = async () => {
    const postAPI = find(myProfile.value?.dataset_set, { id: selectedOption.value * 1 })?.post_api_name;
    const apiName = "/v2/profiles/";

    parseDateFormat(selectedDate.value);

    if(selectedDate.value === undefined || selectedDate.value == ''){
      toast('Please provide a date!', {
        type: 'error',
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if(data_security_pin.value === undefined || data_security_pin.value == ''){
      toast('Please provide your security pin!', {
        type: 'error',
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      base
        .post(
          `${apiName}${postAPI}/?secure_pin=${data_security_pin.value}`,
          {
            ...submitForm.value,
            created_on: date.value,
          }
        )
        .then((response) => {
          if (response.data?.status === "success") {
            toast('Laboratory Data Successfully Added!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });

            data_security_pin.value = "";

            closeModal();
            forceRerender();
          } else {
            toast('Please ensure that all fields have been filled in!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(() => {
          toast('Unable To Add Laboratory Data!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  }

  onMounted(() => {
    verifySecurePin();
  });
</script>
